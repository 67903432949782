import React from "react"
import Layout from "../../components/layout"
import config from "../../utils/siteConfig"
import Button from "../../components/Atoms/Button/Button"
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"
import ContactSupportForm from "../../components/Organisms/ContactSupport/contactSupport"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHome, faBookOpen, faTerminal, faComment } from "@fortawesome/free-solid-svg-icons"

export default function ContactSupport({ data }) {
    debugger;
    const page = data.allContentfulZContactSupport.edges[0].node

    //const Bold = ({ children }) => <span className="bold">{children}</span>
    //const Text = ({ children }) => <p className="align-center">{children}</p>;

    const options = {
        renderNode: {
            [BLOCKS.HEADING_1]: (node, children) => (
                <h1 className="heading1">{children}</h1>
            ),
            [BLOCKS.HEADING_2]: (node, children) => (
                <h2 className="heading2">{children}</h2>
            ),
            [BLOCKS.HEADING_3]: (node, children) => (
                <h3 className="heading3">{children}</h3>
            ),
            [BLOCKS.HEADING_4]: (node, children) => (
                <h4 className="heading4">{children}</h4>
            ),
            [BLOCKS.EMBEDDED_ASSET]: (node, children) => (
                <img src={`https:${node.data.target.fields.file["en-US"].url}`} />
            ),
            [BLOCKS.PARAGRAPH]: (node, children) => (
                <p className="d-inline-block">{children}</p>
            ),
            [BLOCKS.UL_LIST]: (node, children) => {
                return (
                    <ul
                        style={{
                            listStyle: 'disc',
                            lineHeight: '35px',
                            marginLeft: '1rem',
                        }}
                    >
                        {children.map((item) => (
                            <li key={item.key}>{item.props.children[0].props.children[0]}</li>
                        ))}
                    </ul>
                );
            },
            //[BLOCKS.UL_LIST]: (node, children) => (
            //    <span className="d-inline-block bulletSpan">{children}</span>
            //),

        },
        renderMark: {
        },
        renderText: (text) =>
            text
                .replace(/\u2028/g, "")
                .split("\n")
                .flatMap((text, i) => [i > 0 && <br />, text])
    }

    const {
        id,
        metaData,
        cstFRtxt,
        cstFRtxt2,
        cstFRtxt3,
        cstFRtxt4,
        cstFTxt,
        cstHBtn,
        cstHBtn2,
        cstHHd,
        cstHInfo,
        cstHTitle,
        cstHTitle2,
        cstHTitle3,
        cstHTitle4,
    } = page


    return (
        <Layout
            IsHeaderImageDark={false}
            navColour={"white"}>
            <head>
                <title>{metaData.title}</title>
                <meta charset="utf-8" />
                <meta name="description" content={metaData.description} />
                <meta name="keywords" content={metaData.keywords.keywords} />
                <link rel="alternate" hreflang="de" href="https://wunderloop.io/de/contact/email" />
                <link rel="alternate" hreflang="en" href="https://wunderloop.io/en/contact/email" />
            </head>


            <section>
                <div className="sectionFit-container">
                    <div className="row align-items-center">
                        <div className="col-lg-8 pb-5 pr-5">
                            <ContactSupportForm {...page} />
                        </div>
                        <div className="col-lg-4">
                            <div className="colBox7 bg-lightgray">
                                <div className="row text-center pt-2">
                                    <Button
                                        className='btns'
                                        buttonStyle='btn--primaryPurple4'
                                        buttonSize='btn--txt'
                                        href=""
                                    >
                                        Contact Support
                                    </Button>
                                </div>
                                <div className="row">
                                    <p className="p-support pt-1 text-darkgray">
                                        24 x 7 help from our support staff
                                    </p>
                                </div>
                                <div className="row pt-5">
                                    <p className="p-supportBold pt-1 h1-darkBlue">
                                        Popular Topics
                                    </p>
                                </div>
                                <div className="row">
                                    <Button
                                        className='btns'
                                        buttonStyle='btn--primaryPurple4'
                                        buttonSize='btn--txt'
                                        href=""
                                    >
                                        Account
                                    </Button>
                                </div>
                                <div className="row">
                                    <Button
                                        className='btns'
                                        buttonStyle='btn--primaryPurple4'
                                        buttonSize='btn--txt'
                                        href=""
                                    >
                                        Billing
                                    </Button>
                                </div>
                                <div className="row">
                                    <Button
                                        className='btns'
                                        buttonStyle='btn--primaryPurple4'
                                        buttonSize='btn--txt'
                                        href=""
                                    >
                                        Connect
                                    </Button>
                                </div>
                                <div className="row">
                                    <Button
                                        className='btns'
                                        buttonStyle='btn--primaryPurple4'
                                        buttonSize='btn--txt'
                                        href=""
                                    >
                                        Disputes
                                    </Button>
                                </div>
                                <div className="row">
                                    <Button
                                        className='btns'
                                        buttonStyle='btn--primaryPurple4'
                                        buttonSize='btn--txt'
                                        href=""
                                    >
                                        Get Started
                                    </Button>
                                </div>
                                <div className="row">
                                    <Button
                                        className='btns'
                                        buttonStyle='btn--primaryPurple4'
                                        buttonSize='btn--txt'
                                        href=""
                                    >
                                        Payments
                                    </Button>
                                </div>
                                <div className="row">
                                    <Button
                                        className='btns'
                                        buttonStyle='btn--primaryPurple4'
                                        buttonSize='btn--txt'
                                        href=""
                                    >
                                        Payouts
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-lightgray">
                <div className="sectionFit-container4">
                    <div className="row pt-5">
                        <div className="col-lg-3 pl-0 pr-5 pb-5">
                            <FontAwesomeIcon icon={faHome} className="text-purple3 mt-1 mr-4" />
                            <p className="p-regular7 pt-2">
                                {documentToReactComponents(cstFRtxt.json, options)}
                            </p>
                        </div>
                        <div className="col-lg-3 pl-0 pr-5 pb-5">
                            <FontAwesomeIcon icon={faBookOpen} className="text-purple3 mt-1 mr-4" />
                            <p className="p-regular7 pt-2">
                                {documentToReactComponents(cstFRtxt2.json, options)}
                            </p>
                        </div>
                        <div className="col-lg-3 pl-0 pr-5 pb-5">
                            <FontAwesomeIcon icon={faTerminal} className="text-purple3 mt-1 mr-4" />
                            <p className="p-regular7 pt-2">
                                {documentToReactComponents(cstFRtxt3.json, options)}
                            </p>
                        </div>
                        <div className="col-lg-3 pl-0 pr-5 pb-5">
                            <FontAwesomeIcon icon={faComment} className="text-purple3 mt-1 mr-4" />
                            <p className="p-regular7 pt-2">
                                {documentToReactComponents(cstFRtxt4.json, options)}
                            </p>
                        </div>
                    </div>
                </div>
            </section>

        </Layout >
    );
}


export const query = graphql`
query($locale: String!) {
    allContentfulZContactSupport(
        filter: {
        node_locale: { eq: $locale }
    }) {
        edges {
            node {
                id
                metaData {
                    slug
                    title
                    description
                    keywords {
                        keywords
                    }
                }
                cstFRtxt {
                    json
                  }
                  cstFRtxt2 {
                    json
                  }
                  cstFRtxt3 {
                    json
                  }
                  cstFRtxt4 {
                    json
                  }
                  cstFTxt
                  cstHBtn
                  cstHBtn2
                  cstHHd
                  cstHInfo
                  cstHTitle
                  cstHTitle2
                  cstHTitle3
                  cstHTitle4
            }
        }
    }
}
`

import React, { useState } from "react"
import { navigateTo } from "gatsby-link"
import Button from "../../../components/Atoms/Button/Button"
import contactFormStyles from "./contactSupportForm.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faArrowUp,
  faQuestionCircle,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props)
    debugger
    this.props = props
    this.state = {}
  }

  debugger

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleAttachment = e => {
    this.setState({ [e.target.name]: e.target.files[0] })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    Promise.all([
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "multipart/form-data" },
        body: encode({
          "form-name": form.getAttribute("name"),
          ...this.state,
        }),
      }),
      fetch("https://getform.io/f/f8237525-74ff-4231-adc6-7a47da409cd5", {
        method: "POST",
        headers: { "Content-Type": "multipart/form-data" },
        body: encode({
          "form-name": form.getAttribute("name"),
          ...this.state,
        }),
      }),
    ])
      .then(() => navigateTo("/thankyou"))
      .catch(error => alert(error))
  }

  render() {
    return (
      <div className={contactFormStyles.contactForm}>
        <form
          name="contactSupport"
          method="post"
          // action="https://getform.io/f/f8237525-74ff-4231-adc6-7a47da409cd5"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={this.handleSubmit}
        >
          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <input type="hidden" name="form-name" value="contactSupport" />
          <p hidden>
            <label>
              Don’t fill this out:{" "}
              <input name="bot-field" onChange={this.handleChange} />
            </label>
          </p>
          <h4> {this.props.cstHHd} </h4>
          <div className="block pt-5">
            <label for="questionType">{this.props.cstHTitle}</label>
            <select
              type="text"
              id="questionType"
              name="questionType"
              onChange={this.handleChange}
              required
            >
              <option value="Other" selected hidden>
                Other
              </option>
              <option value="API Integration">API Integration</option>
              <option value="Atlas">Atlas</option>
              <option value="Connect">Connect</option>
              <option value="Declines">Declines</option>
              <option value="Disputes">Disputes</option>
              <option value="Financial Reports">Financial Reports</option>
              <option value="Login Issues">Login Issues</option>
              <option value="Payment APIs">Payment APIs</option>
              <option value="Other">Other</option>
            </select>
          </div>
          <div className="block pt-3 pb-2">
            <label for="question">{this.props.cstHTitle2}</label>
            <input
              type="text"
              id="question"
              name="question"
              onChange={this.handleChange}
              pattern=".{,50}"
              required
              placeholder={this.props.csFPh2}
              title="Not more than 50 symbols"
            />
          </div>
          {/* <div className="block">
            <label for="workEmail">{this.props.cstHTitle3}</label>
            
    </div> */}
          <div className="block pt-3 pb-2">
            <label
              className={contactFormStyles.informationLabel}
              for="howCanWeHelp"
            >
              {this.props.cstHTitle4}
            </label>
            <textarea
              type="text"
              name="howCanWeHelp"
              id="howCanWeHelp"
              onChange={this.handleChange}
              title="Not more than 300 Words"
            />
          </div>
          <div className="row pl-3 pr-3 pt-3">
            <label
              for="file"
              style={{
                cursor: "pointer",
              }}
            >
              {this.props.cstHBtn}
            </label>
            <input
              id="file"
              type="file"
              name="picture"
              onChange={this.handleAttachment}
              style={{ opacity: 0, height: "0px", width:"0px"}}
            />
          </div>
          <div className="row justify-content-end pr-3 pt-3 pl-3">
            <Button
              name="submit"
              type="submit"
              className="btns"
              buttonStyle="btn--outlinePurpleBlue"
              buttonSize="btn--medium"
            >
              {this.props.cstHBtn2}
            </Button>
          </div>
        </form>
      </div>
    )
  }
}
